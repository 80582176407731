import { Flex, Input, Form, Button, Card, theme, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { getUser, updateAuthToken } from '../client';
import { CanceledError } from 'axios';
import { AppContext } from '../context';

const requiredRules = [{ required: true, message: 'Token required!' }];

type CredentialsType = { token: string };

export default function Auth() {
  const { token: themeToken } = theme.useToken();
  const [processing, setProcessing] = useState(false);
  const [credentials, setCredentials] = useState<CredentialsType>({
    token: '',
  });
  const [messageApi, contextHolder] = message.useMessage();
  const { setUser } = useContext(AppContext);

  useEffect(() => {
    if (!processing) return;
    const abortController = new AbortController();
    (async () => {
      try {
        updateAuthToken(credentials.token);
        const user = await getUser(abortController);
        setUser(user);
      } catch (error) {
        if (error instanceof CanceledError) return;
        messageApi.error('Login error');
        console.log('Login error:', error);
      }
      setProcessing(false);
    })();
    return () => {
      abortController.abort();
    };
  }, [processing, messageApi, credentials, setUser]);

  return (
    <Flex
      className="auth-container"
      justify="center"
      align="center"
      style={{ backgroundColor: themeToken.colorPrimary }}
    >
      {contextHolder}
      <Card>
        <Form
          className="token-auth-form"
          onFinish={(newParams: Record<string, any>) => {
            setCredentials(newParams as CredentialsType);
            setProcessing(true);
          }}
        >
          <Form.Item
            key={'param-token'}
            name="token"
            label="Token"
            rules={requiredRules}
            required={false}
          >
            <Input.Password autoComplete="password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={processing}>
              Login
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Flex>
  );
}
