import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button,
  Flex,
  Form,
  GetRef,
  Input,
  message,
  Modal,
  Table,
  TableProps,
  Typography,
} from 'antd';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  addProject,
  deleteProject,
  listProjects,
  NewProjectData,
  updateProject,
} from '../client';
import { AxiosError, CanceledError } from 'axios';
import { AppContext } from '../context';

type ProjectListData = {
  key: string;
  name: string;
};

const requiredRules = [{ required: true, message: 'Required parameter!' }];

function AddModal({
  open,
  onClose,
  onSuccess,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}) {
  const [processing, setProcessing] = useState(false);
  const [projectData, setProjectData] = useState<NewProjectData | null>(null);
  const [changed, setChanged] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (!processing) return;
    if (!projectData) {
      setProcessing(false);
      return;
    }
    const abortController = new AbortController();
    (async () => {
      try {
        await addProject(projectData, abortController);
        setProcessing(false);
        onClose();
        onSuccess();
        message.success('Added');
      } catch (error) {
        if (error instanceof CanceledError) return;
        setProcessing(false);
        messageApi.error('Error adding project');
        console.log('Error adding project:', error);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [processing, onSuccess, onClose, projectData, messageApi]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      // onOk={() => {
      //   setProcessing(true);
      // }}
      closeIcon={null}
      okButtonProps={{
        loading: processing,
        disabled: !changed,
        form: 'add-project-form',
        // key: 'submit',
        htmlType: 'submit',
      }}
    >
      {contextHolder}
      <Typography.Title level={5}>Add</Typography.Title>
      <Form
        name="add-project-form"
        className="project-create-form"
        onFinish={(newParams: Record<string, any>) => {
          setProjectData(newParams as NewProjectData);
          setChanged(false);
          setProcessing(true);
          console.log('new settings', newParams);
        }}
        onChange={() => setChanged(true)}
      >
        <Form.Item
          key={'param-name'}
          name="name"
          label="Name"
          rules={requiredRules}
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          key={'param-token'}
          name="project_token"
          label="Token"
          rules={requiredRules}
          required
        >
          <Input.Password autoComplete="password" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

function DeleteModal({
  open,
  onClose,
  onSuccess,
  projectId,
  projectName,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  projectId: string;
  projectName: string;
}) {
  const [processing, setProcessing] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    if (!processing || !projectId) return;
    const abortController = new AbortController();
    (async () => {
      try {
        await deleteProject(projectId, abortController);
        setProcessing(false);
        onClose();
        onSuccess();
        message.success('Deleted');
      } catch (error) {
        if (error instanceof CanceledError) return;
        setProcessing(false);
        messageApi.error('Error deleting project');
        console.log('Error deleting project:', error);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [processing, onSuccess, onClose, projectId, messageApi]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onOk={() => {
        setProcessing(true);
      }}
      closeIcon={null}
      okButtonProps={{ loading: processing, danger: true }}
      okText="Delete"
    >
      {contextHolder}
      <Typography.Title level={5}>Delete {projectName}?</Typography.Title>
    </Modal>
  );
}

export default function ProjectsList() {
  const [opennedModal, setOpennedModal] = useState<'add' | 'delete' | null>(
    null
  );
  const [currentProject, setCurrentProject] = useState<ProjectListData | null>(
    null
  );

  const [data, setData] = useState<ProjectListData[]>([]);
  const [loading, setLoading] = useState(true);
  const [fetchRequested, setFetchRequested] = useState(true);

  const [messageApi, contextHolder] = message.useMessage();

  const { projectId, setProjectId, setProjectName } = useContext(AppContext);

  useEffect(() => {
    if (!fetchRequested) return;
    const abortController = new AbortController();
    setLoading(true);
    (async () => {
      try {
        const projects = await listProjects(abortController);
        const tableData = projects.map(
          ({ id, name }) => ({ name, key: id.toString(10) } as ProjectListData)
        );
        tableData.sort((a, b) => a.name.localeCompare(b.name));
        setData(tableData);
        setLoading(false);
        setFetchRequested(false);
        if (!projectId && projects.length && setProjectId && setProjectName) {
          setProjectId(projects[0].id.toString(10));
          setProjectName(projects[0].name);
        }
      } catch (error) {
        if (error instanceof CanceledError) return;
        messageApi.error('Error fetching project list');
        setLoading(false);
        setFetchRequested(false);
        console.log('Error fetching project list:', error);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [fetchRequested, messageApi, projectId, setProjectId, setProjectName]);

  const onModified = useCallback(() => {
    setFetchRequested(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpennedModal(null);
  }, []);

  const columns: TableProps<ProjectListData>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value: string, record) => (
        <>
          {value}
          <Button
            className="project-list-delete-btn"
            shape="circle"
            type="text"
            icon={<DeleteOutlined />}
            onClick={(event) => {
              setCurrentProject(record);
              setOpennedModal('delete');
            }}
          />
        </>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Flex
        vertical
        align="flex-start"
        gap="middle"
        className="project-list-container"
      >
        <Button
          type="primary"
          size="large"
          onClick={() => {
            setCurrentProject(null);
            setOpennedModal('add');
          }}
        >
          Add
        </Button>
        <Table
          className="project-list"
          columns={columns}
          dataSource={data}
          showHeader={false}
          loading={loading}
          pagination={false}
          rowSelection={{
            type: 'radio',
            onChange: (selectedRowKeys) => {
              if (setProjectName && selectedRowKeys.length) {
                const newProjectId = selectedRowKeys[0].toString();
                setProjectId(newProjectId);
                setProjectName(
                  data.find((project) => project.key === newProjectId)?.name ||
                    ''
                );
              }
            },
            selectedRowKeys: projectId ? [projectId] : [],
          }}
        />
        <AddModal
          open={opennedModal === 'add'}
          onClose={closeModal}
          onSuccess={onModified}
        />
        <DeleteModal
          open={opennedModal === 'delete'}
          onClose={closeModal}
          onSuccess={onModified}
          projectId={currentProject?.key || ''}
          projectName={currentProject?.name || ''}
        />
      </Flex>
    </>
  );
}
