import {
  Button,
  Card,
  Checkbox,
  Empty,
  Flex,
  Form,
  Input,
  InputNumber,
  message,
  Spin,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { getProject, ProjectData, updateProject } from '../client';
import { AppContext } from '../context';
import { CanceledError } from 'axios';
import LoadingPlaceholder from './LoadingPlaceholder';

const requiredRules = [{ required: true, message: 'Required parameter!' }];

export default function ProjectSettings() {
  const [saving, setSaving] = useState(false);
  const [projectData, setProjectData] = useState<ProjectData | null>(null);
  const [changed, setChanged] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { projectId, setProjectName } = useContext(AppContext);
  const [updateGptLimit, setUpdateGptLimit] = useState(false);

  useEffect(() => {
    if (!projectId) return;
    const abortController = new AbortController();
    (async () => {
      try {
        const projData = await getProject(projectId, abortController);
        setProjectData(projData);
      } catch (error) {
        if (error instanceof CanceledError) return;
        messageApi.error('Error fetching project');
        console.log('Error fetching project:', error);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [projectId, messageApi]);

  useEffect(() => {
    if (!projectId || !projectData || !saving) return;
    const abortController = new AbortController();
    (async () => {
      try {
        await updateProject(projectId, projectData, abortController);
        setProjectName(projectData.name);
        messageApi.success('Saved');
      } catch (error) {
        if (error instanceof CanceledError) return;
        messageApi.error('Error fetching project');
        console.log('Error fetching project:', error);
      }
      setSaving(false);
    })();
    return () => {
      abortController.abort();
    };
  }, [projectId, messageApi, projectData, saving, setProjectName]);

  if (!projectData) {
    return (
      <>
        {contextHolder}
        <LoadingPlaceholder />
      </>
    );
  }

  return (
    <Card>
      {contextHolder}
      <Flex justify="center">
        <Form
          className="project-settings-form"
          initialValues={projectData}
          onFinish={(newParams: Record<string, any>) => {
            setProjectData(newParams as ProjectData);
            setChanged(false);
            setSaving(true);
            console.log('new settings', newParams);
          }}
          onChange={() => setChanged(true)}
        >
          <Form.Item
            key={'param-name'}
            name="name"
            label="Name"
            rules={requiredRules}
            required={false}
          >
            <Input />
          </Form.Item>
          <Form.Item
            key={'param-gpt-key'}
            name="gpt_key"
            label="GPT key"
            required={false}
          >
            <Input />
          </Form.Item>
          <Form.Item
            key={'param-update-gpt-key-limit'}
            name="update_gpt_key_limit"
            label="Update GPT key limit"
            required={false}
            valuePropName="checked"
          >
            <Checkbox
              checked={updateGptLimit}
              onChange={(e) => {
                setUpdateGptLimit(e.target.checked);
              }}
            />
          </Form.Item>
          <Form.Item
            key={'param-gpt-key-limit'}
            name="gpt_key_limit"
            label="GPT key limit"
            required={false}
          >
            <InputNumber disabled={!updateGptLimit} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={!changed}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Flex>
    </Card>
  );
}
