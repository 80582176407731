import { Handle, Node, NodeProps, Position } from "@xyflow/react";

export type IONodeType = Node<
  {
    isOutput?: boolean;
    isMultiInput?: boolean;
    isMultiOutput?: boolean;
  },
  "IO"
>;

export default function IONode({ data, isConnectable }: NodeProps<IONodeType>) {
  const { isOutput, isMultiInput, isMultiOutput } = data;
  return (
    <div className="io-node">
      {isOutput && (
        <Handle
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
          className={isMultiInput ? "multi-handle" : ""}
        />
      )}
      {isOutput ? "Output" : "Input"}
      {!isOutput && (
        <Handle
          type="source"
          position={Position.Bottom}
          isConnectable={isConnectable}
          className={isMultiOutput ? "multi-handle" : ""}
        />
      )}
    </div>
  );
}
