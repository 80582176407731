import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import { Button, Form, Input } from 'antd';
import { useState } from 'react';

type ParamDisplayType = 'text' | 'textarea';

export type OperatorParamType = {
  name: string;
  value?: string;
  label?: string;
  required?: boolean;
  type?: ParamDisplayType;
};

export type OperatorNodeType = Node<
  {
    operatorName: string;
    label?: string;
    isMultiInput?: boolean;
    isMultiOutput?: boolean;
    params?: OperatorParamType[];
    onParamsChange?: (nodeId: string, params: OperatorParamType[]) => void;
    bgColor?: string;
  },
  'Operator'
>;

export default function OperatorNode({
  data,
  isConnectable,
  id: nodeId,
}: NodeProps<OperatorNodeType>) {
  const {
    operatorName,
    isMultiInput,
    isMultiOutput,
    label,
    params,
    onParamsChange,
    bgColor,
  } = data;
  const [changed, setChanged] = useState(false);
  const formValues = Object.fromEntries(
    params?.map((param) => [param.name, param.value]) || []
  );

  const form = params && (
    <Form
      className="operator-node-params-form"
      initialValues={formValues}
      onFinish={(newParams: Record<string, any>) => {
        if (onParamsChange)
          onParamsChange(
            nodeId,
            params.map((param) => ({ ...param, value: newParams[param.name] }))
          );
        setChanged(false);
      }}
      onChange={() => setChanged(true)}
    >
      {(params || []).map((param, idx) => (
        <Form.Item
          key={`param-${idx}`}
          name={param.name}
          label={param.label}
          required={param.required}
          rules={
            param.required
              ? [{ required: true, message: 'Required parameter!' }]
              : undefined
          }
        >
          {param.type === 'textarea' ? (
            <Input.TextArea className="nodrag" cols={60} />
          ) : (
            <Input className="nodrag" />
          )}
        </Form.Item>
      ))}
      {changed && (
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      )}
    </Form>
  );

  const style = bgColor ? { backgroundColor: bgColor } : undefined;

  return (
    <div className="operator-node" style={style}>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
        className={isMultiInput ? 'multi-handle' : ''}
      />
      {label || operatorName}
      {form}
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
        className={isMultiOutput ? 'multi-handle' : ''}
      />
    </div>
  );
}
