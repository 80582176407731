import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import { Button, Select, Space } from 'antd';
import { Link } from 'react-router-dom';
import { GraphListEntry } from '../../client';

export type SubgraphNodeType = Node<
  {
    subgraphId?: string;
    type: 'subgraph' | 'foreach';
    graphs: GraphListEntry[];
    isMultiInput?: boolean;
    isMultiOutput?: boolean;
    onSubgraphChange?: (nodeId: string, subgraphId: string) => void;
  },
  'Subgraph'
>;

function SubgraphNode({
  id,
  data: {
    subgraphId,
    type,
    graphs,
    isMultiInput,
    isMultiOutput,
    onSubgraphChange,
  },
  isConnectable,
}: NodeProps<SubgraphNodeType>) {
  const onChange = (value: string) => {
    if (onSubgraphChange) {
      onSubgraphChange(id, value);
    }
  };

  const options = graphs.map((graph) => ({
    value: graph.id,
    label: graph.name,
  }));

  const selectId = `subgraph-${subgraphId}`;

  return (
    <div className={type === 'foreach' ? 'foreach-node' : 'subgraph-node'}>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
        className={isMultiInput || type === 'foreach' ? 'multi-handle' : ''}
      />
      <div>
        <label htmlFor={selectId}>
          {type === 'foreach' ? 'ForEach' : 'Subgraph'}
        </label>
        <Select
          id={selectId}
          onChange={onChange}
          className="nodrag subgraph-select"
          value={subgraphId}
          options={options}
          showSearch
        />
      </div>
      {subgraphId && (
        <div className="subgraph-edit-link">
          <Link to={`/graphs/${subgraphId}`}>
            <Button>Edit</Button>
          </Link>
        </div>
      )}
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
        className={isMultiOutput || type === 'foreach' ? 'multi-handle' : ''}
      />
    </div>
  );
}

export default SubgraphNode;
