import { Handle, Node, NodeProps, Position } from '@xyflow/react';

export type IfNodeType = Node<{}, 'If'>;

export default function IfNode({ isConnectable }: NodeProps<IfNodeType>) {
  return (
    <div className="if-node">
      <Handle
        id='data'
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
        className="if-node-data-handle"

      />
      <Handle
        id='condition'
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
        className="if-node-condition-handle"
      />
      If
      <Handle
        id='true'
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
        className="if-node-true-handle"
      />
      <Handle
        id='false'
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
        className="if-node-false-handle"
      />
    </div>
  );
}
