import { Edge, Node } from '@xyflow/react';
import axios from 'axios';
import { OperatorParamType } from './components/nodes/OperatorNode';

type IOType = 'single' | 'multi';

type Graph = {
  id: string;
  nodes: Node[];
  edges: Edge[];
};

type GraphData = {
  id: string;
  name?: string;
  graph?: Graph;
};

export type GraphListEntry = {
  id: string;
  name?: string;
  isMultiInput?: boolean;
  isMultiOutput?: boolean;
};

export type ProjectData = {
  id: number;
  name: string;
  gpt_key?: string;
  gpt_key_limit?: number;
};

export type NewProjectData = {
  name: string;
  project_token: string;
};

export type User = {
  id: number;
  name?: string | null;
  is_admin: boolean;
};

export type NodeDescription = {
  input_type: IOType;
  output_type: IOType;
  name: string;
  label: string;
  parameters: OperatorParamType[];
};

let client = axios.create({
  baseURL: '/api/',
});

export function updateAuthToken(token: string | null) {
  const headers = token ? { Authorization: `Bearer ${token}` } : {};
  client = axios.create({
    baseURL: '/api/',
    headers,
  });
  if (token) {
    localStorage.setItem('authToken', token);
  } else {
    localStorage.removeItem('authToken');
  }
}

// Graphs

export async function listGraphs(
  projectId: string,
  abortController?: AbortController
) {
  const response = await client.get(`/projects/${projectId}/graphs`, {
    signal: abortController?.signal,
  });
  return response.data.graphs as GraphListEntry[];
}

export async function getGraph(
  projectId: string,
  graphId: string,
  abortController?: AbortController
) {
  const response = await client.get(
    `/projects/${projectId}/graphs/${graphId}`,
    {
      signal: abortController?.signal,
    }
  );
  return response.data as GraphData;
}

export async function addGraph(
  projectId: string,
  name: string,
  abortController?: AbortController
) {
  await client.post(
    `/projects/${projectId}/graphs`,
    { name },
    {
      signal: abortController?.signal,
    }
  );
}
export async function updateGraph(
  projectId: string,
  graphId: string,
  graphData: GraphData,
  abortController?: AbortController
) {
  await client.patch(`/projects/${projectId}/graphs/${graphId}`, graphData, {
    signal: abortController?.signal,
  });
}

export async function deleteGraph(
  projectId: string,
  graphId: string,
  abortController?: AbortController
) {
  await client.delete(`/projects/${projectId}/graphs/${graphId}`, {
    signal: abortController?.signal,
  });
}

export async function executeGraph(
  projectId: string,
  graphId: string,
  inputText: string,
  abortController?: AbortController
) {
  const response = await client.post(
    `/projects/${projectId}/graphs/${graphId}/execute`,
    { input: inputText },
    {
      signal: abortController?.signal,
    }
  );
  const respData = response.data as { output: any[] };
  return respData.output;
}

// Projects

export async function listProjects(abortController?: AbortController) {
  const response = await client.get(`/projects`, {
    signal: abortController?.signal,
  });
  return response.data.projects as ProjectData[];
}

export async function getProject(
  projectId: string,
  abortController?: AbortController
) {
  const response = await client.get(`/projects/${projectId}`, {
    signal: abortController?.signal,
  });
  return response.data as ProjectData;
}

export async function addProject(
  data: NewProjectData,
  abortController?: AbortController
) {
  return await client.post(`/projects`, data, {
    signal: abortController?.signal,
  });
}
export async function updateProject(
  projectId: string,
  projectData: ProjectData,
  abortController?: AbortController
) {
  await client.patch(`/projects/${projectId}`, projectData, {
    signal: abortController?.signal,
  });
}

export async function deleteProject(
  projectId: string,
  abortController?: AbortController
) {
  await client.delete(`/projects/${projectId}`, {
    signal: abortController?.signal,
  });
}

export async function getUser(abortController?: AbortController) {
  const resp = await client.get(`/user`, {
    signal: abortController?.signal,
  });
  return resp.data as User;
}

export async function getOperatorNodesDescriptions(
  abortController?: AbortController
) {
  const resp = await client.get(`/operator-nodes`, {
    signal: abortController?.signal,
  });
  return resp.data as NodeDescription[];
}
