import React, { useContext, useEffect, useState } from 'react';
import './App.css';

import { Button, Drawer, Layout, Menu, message } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { MenuItemType } from 'antd/es/menu/interface';
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { BarsOutlined, LogoutOutlined } from '@ant-design/icons';

import GraphEditor from './components/GraphEditor';
import GraphsList from './components/GraphsList';
import { AppContext } from './context';
import ProjectsList from './components/ProjectsList';
import ProjectSettings from './components/ProjectSettings';
import { getUser, listProjects, updateAuthToken, User } from './client';
import Auth from './components/Auth';
import { CanceledError } from 'axios';
import LoadingPlaceholder from './components/LoadingPlaceholder';

type PageId = 'endpoints' | 'graphs' | 'settings';

type NavMenuItemType = MenuItemType & {
  key: PageId;
};

function App({ loading }: { loading?: boolean }) {
  const location = useLocation();
  const { projectId, projectName, user, setUser } = useContext(AppContext);
  const [projectListOpen, setProjectListOpen] = useState(false);

  let navPage: string | null = null;
  switch (location.pathname) {
    case '/endpoints':
    case '/graphs':
    case '/settings':
      navPage = location.pathname.slice(1);
      break;
  }

  const navMenuItems: NavMenuItemType[] = projectId
    ? [
        {
          key: 'endpoints',
          label: <Link to="/endpoints">Endpoints</Link>,
        },
        {
          key: 'graphs',
          label: <Link to="/graphs">Graphs</Link>,
        },
        {
          key: 'settings',
          label: <Link to="/settings">Settings</Link>,
        },
      ]
    : [];

  if (loading) {
    return (
      <div className="app">
        <LoadingPlaceholder />
      </div>
    );
  }

  if (!user) {
    return (
      <div className="app">
        <Auth />
      </div>
    );
  }

  return (
    <Layout className="app">
      <Header className="app-header">
        {user.is_admin && (
          <Button
            shape="circle"
            icon={<BarsOutlined />}
            size="large"
            className="open-nav-button"
            onClick={() => setProjectListOpen(true)}
          />
        )}
        <Link className="app-label" to="/">
          LLM Pipeline Editor
        </Link>
        <Menu
          className="app-nav"
          items={navMenuItems}
          selectedKeys={navPage ? [navPage] : undefined}
          mode="horizontal"
          theme="dark"
        />
        <p className="app-header-project-name">{projectName}</p>
        <p>{user.name}</p>
        <Button
          type="link"
          icon={<LogoutOutlined />}
          onClick={() => {
            setUser(null);
            updateAuthToken(null);
          }}
        />
      </Header>
      {user.is_admin && (
        <Drawer
          title="Projects"
          open={projectListOpen}
          onClose={() => setProjectListOpen(false)}
          placement="left"
          forceRender
        >
          <ProjectsList />
        </Drawer>
      )}
      <Content className="app-content">
        <Routes>
          <Route path="/" element={<></>} />
          <Route path="/endpoints" element={<></>} />
          <Route path="/graphs" element={<GraphsList />} />
          <Route path="/graphs/:graphId" element={<GraphEditor />} />
          <Route path="/settings" element={<ProjectSettings />} />
        </Routes>
      </Content>
    </Layout>
  );
}

export default function AppContainer() {
  const [projectId, setProjectId] = useState<string | null>(null);
  const [projectName, setProjectName] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [fetchingProjects, setFetchingProjects] = useState(false);
  const [fetchingUser, setFetchingUser] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      setFetchingUser(false);
      return;
    }
    setFetchingUser(true);
    updateAuthToken(authToken);
    const abortController = new AbortController();
    (async () => {
      try {
        const user = await getUser(abortController);
        setUser(user);
        setFetchingProjects(true);
      } catch (error) {
        if (error instanceof CanceledError) return;
        messageApi.error('Login error');
        console.log('Login error:', error);
      }
      setFetchingUser(false);
    })();
    return () => {
      abortController.abort();
    };
  }, [messageApi]);

  useEffect(() => {
    if (!user) return;
    const abortController = new AbortController();
    setFetchingProjects(true);
    (async () => {
      try {
        const projects = await listProjects(abortController);
        setFetchingProjects(false);
        const lastProjectId = localStorage.getItem('projectId');
        if (lastProjectId) {
          const lastProject = projects.find(
            (project) => project.id.toString(10) === lastProjectId
          );
          if (lastProject) {
            setProjectId(lastProjectId);
            setProjectName(lastProject.name);
            return;
          }
        }
        if (projects.length) {
          const project = projects[0];
          const pId = project.id.toString(10);
          setProjectId(pId);
          setProjectName(project.name);
          localStorage.setItem('projectId', pId);
        }
      } catch (error) {
        if (error instanceof CanceledError) return;
        messageApi.error('Error fetching project list');
        console.log('Error fetching project list:', error);
      }
      setFetchingProjects(false);
    })();
    return () => {
      abortController.abort();
    };
  }, [user, messageApi]);

  console.log('projectId', projectId);
  return (
    <AppContext.Provider
      value={{
        projectId,
        setProjectId,
        projectName,
        setProjectName,
        user,
        setUser,
      }}
    >
      {contextHolder}
      <App loading={fetchingUser || fetchingProjects} />
    </AppContext.Provider>
  );
}
