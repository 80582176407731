import { useContext, useEffect, useRef, useState } from 'react';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Button, Card, Col, Flex, GetRef, message, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { AxiosError, CanceledError } from 'axios';
import { executeGraph } from '../client';
import { AppContext } from '../context';

type TestPanelProps = {
  graphId: string;
  onClose: () => void;
};

export default function TestPanel({ graphId, onClose }: TestPanelProps) {
  const [executing, setExecuting] = useState(false);
  const [result, setResult] = useState('');
  const testInputRef = useRef<GetRef<typeof TextArea>>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const { projectId } = useContext(AppContext);

  useEffect(() => {
    if (!executing || !projectId) return;
    const inputText = testInputRef.current?.resizableTextArea?.textArea.value;
    console.log('inputText', inputText);
    console.log('testInputRef.current', testInputRef.current);
    if (!inputText) {
      setExecuting(false);
      return;
    }
    const abortController = new AbortController();
    (async () => {
      try {
        const resultObj = await executeGraph(
          projectId,
          graphId,
          inputText,
          abortController
        );
        if (resultObj instanceof Array) {
          setResult(resultObj.join('\n\n================\n\n'));
        } else {
          setResult(JSON.stringify(resultObj));
        }
      } catch (error) {
        if (error instanceof CanceledError) return;
        messageApi.error('Error executing graph');
        console.log('Error executing graph:', error);
        if (error instanceof AxiosError) {
          setResult(JSON.stringify(error.response?.data));
        }
      }
      setExecuting(false);
    })();
    return () => {
      abortController.abort();
    };
  }, [executing, graphId, messageApi, projectId]);

  return (
    <div className="test-panel">
      {contextHolder}
      <div className="test-panel-left-col">
        <div className="test-panel-buttons">
          <Button icon={<CaretDownOutlined />} onClick={onClose}>
            Hide
          </Button>
          <Button
            icon={<CaretRightOutlined />}
            onClick={() => setExecuting(true)}
            loading={executing}
            type="primary"
          >
            Run
          </Button>
        </div>
        <TextArea
          ref={testInputRef}
          placeholder="Input text"
          disabled={executing}
        />
      </div>
      <div className="test-panel-right-col">
        <TextArea value={result} placeholder="Result" readOnly />
      </div>
    </div>
  );
}
